import React, { Component } from 'react';
import { Button, FormGroup, InputGroup, Checkbox  } from "@blueprintjs/core";
import axios from 'axios';
import SourceEmitter from '../../lib/emitter';

import './form.scss'

const DFA_API_SERVICES_URL = process.env.DFA_API_SERVICES_URL; 

class UnsubForm extends Component {
    constructor(props) {
        super()
        this.state = {
            emailValue: '',
            firstNameValue: '',
            lastNameValue: '',
            npiValue: '',
            unsub: false,
            FormError: false,
            email_Error: false,
            firstName_Error: false,
            lastName_Error: false,
            npi_Error: false,
        };

        this.handleEmail = this.handleEmail.bind(this);
        this.handleNPI = this.handleNPI.bind(this);
        this.handleFirstName = this.handleFirstName.bind(this);
        this.handleLastName = this.handleLastName.bind(this);
        this.handleUnsub = this.handleUnsub.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }

    handleEmail = val => {
        this.setState({
          emailValue: val.target.value
        });
        
      };

    handleFirstName = val => {
      this.setState({
        firstNameValue: val.target.value
      });
      
    };
    handleLastName = val => {
      this.setState({
        lastNameValue: val.target.value
      });
    };

    handleNPI = val => {
      const reg = /^-?\d+\.?\d*$/; 
      // limit to only numbers
      if (!reg.test(val.target.value) && val.target.value !== '') {return;}

      const maxLength = 10;
      const limit = val.target.value.substring(0, maxLength)
      this.setState({
        npiValue: limit
      });
      
    };
    
   
    handleUnsub = val => {
        this.setState({
            unsub: !this.state.unsub
        });
    };


     
    validateEmail = _email => {
        const emailREGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        return emailREGEX.test(_email);
    };

    handleSubmit = () => {
      const validEmail = this.validateEmail(this.state.emailValue)
        console.log('boom submit, is email valid?, ', validEmail)
        if (
          !validEmail
        ) {
          this.setState({
            FormError: true, 
            email_Error: true,
          });
          alert('Error: Form fields are invalid.');
        } else {
          this.setState({
            FormError: false
          });
    
          //send form data
          //axios.get/post
          const unsubscribe = (this.state.unsub) ? "Y":"N";
          const dataToSend = {
            data: {
              unsub: unsubscribe,
              email: this.state.emailValue,
              first_name: this.state.firstNameValue,
              last_name: this.state.lastNameValue,
              npi: this.state.npiValue,
              client: 'ucb',
              product: 'ucb-corp',
              specific: 'briviact vimpat vision unsubscribe',
              submit_date: Date.now(),
              Environment: process.env.CUSTOM_ENV_VAR
            }
          };
    
          //Submit form
          axios
            .post(DFA_API_SERVICES_URL, dataToSend)
            .then(response => {
              //console.log(response);
              //Clear statea
              this.setState(
                  {
                    emailValue: '',
                    firstNameValue: '',
                    lastNameValue: '',
                    npiValue: '',
                    unsub: false,
                    FormError: false,
                    email_Error: false,
                    firstName_Error: false,
                    lastName_Error: false,
                    npi_Error: false,
                  }
              );
              SourceEmitter.emit(`FormSubmitted`, true);
            })
            .catch(error => {
              console.log(error);
            });
          //console.log(dataToSend);
        }
      };
    
    render() {
        const { emailValue, unsub, email_Error, firstNameValue, firstName_Error, lastNameValue, lastName_Error, npiValue, npi_Error } = this.state;
        const { email } = this.props;


        return (

            <div>
                <FormGroup
                    helperText="Please add your First Name"
                    label="First Name"
                    labelFor="text-email"
                    labelInfo="(required)"
                    >
                     <InputGroup id="text-firstName" intent={ (firstName_Error && 'danger') || 'primary' } placeholder="Jon" large onChange={this.handleFirstName} value={firstNameValue}  />
                </FormGroup>
                <FormGroup
                    helperText="Please add your Last Name"
                    label="Last Name"
                    labelFor="text-email"
                    labelInfo="(required)"
                    >
                     <InputGroup id="text-lastName" intent={ (lastName_Error && 'danger') || 'primary' } placeholder="Smith" large onChange={this.handleLastName} value={lastNameValue}  />
                </FormGroup>
                <FormGroup
                    helperText="Please add your NPI number"
                    label="NPI Number"
                    labelFor="text-email"
                    labelInfo="(required)"
                    >
                     <InputGroup id="text-NPI-number" intent={ (npi_Error && 'danger') || 'primary' } placeholder="NPI Number" large onChange={this.handleNPI} value={npiValue}  />
                </FormGroup>
                <FormGroup
                    helperText="Please add your email"
                    label="Email"
                    labelFor="text-email"
                    labelInfo="(required)"
                    >
                    <InputGroup id="text-email" intent={ (email_Error && 'danger') || 'primary' } placeholder="jon.smith@example.com" large onChange={this.handleEmail} value={emailValue} />
                </FormGroup>
                <Checkbox checked={unsub} onChange={this.handleUnsub}>
                Please unsubscribe me from BRIVIACT and VIMPAT emails sent from my UCB representative
                </Checkbox>

                <Button intent="primary" type='submit' large text="Submit" disabled={ !unsub || lastNameValue === '' || firstNameValue === '' && emailValue === ''} onClick={this.handleSubmit} />
            </div>
        );
    }
};

export default UnsubForm;
